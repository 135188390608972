import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';

import makeStyles from '@mui/styles/makeStyles';

import { DataGrid } from '@mui/x-data-grid';

import { colors } from '../lib/styles';
import useUser from '../lib/useUser';
import Layout from '../components/layout';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{Number(params.row.amount).toFixed(2)}</span>;
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    align: 'right',
    width: 170,
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return <span>{params.row.created_at ? moment(params.row.created_at).format('YYYY-MM-DD') : ''}</span>;
    },
  },
  {
    field: 'bsf_id',
    headerName: 'Conrtract Number',
    align: 'right',
    width: 200,
    renderCell: (params) => {
      const { row } = params;
      if (row.bsf_id) {
        return <Link to={`/bsfs/${params.row.bsf_id}`} style={{ color: colors.darkAccent }}>{row.policy_number}</Link>;
      }
      return '';
    },
  },
  {
    field: 'carrier_code',
    headerName: 'Carrier',
    align: 'right',
    width: 150,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    align: 'left',
    width: 300,
  },
];

const Chargebacks = () => {
  const classes = useStyles();
  const [err] = useState(null);
  const user = useUser();
  const path = `/users/${user.id}/chargebacks?orderBy=created_at`;
  const { data: payments, loading: paymentsLoading } = useSWR(path);

  return (
    <Layout>
      <div>{err?.toString()}</div>
      <Paper>
        {!paymentsLoading ? (
          <div className={classes.gridContainer}>
            <DataGrid columns={columns} rows={payments || []} />
          </div>
        ) : <CircularProgress/>}
      </Paper>
    </Layout>
  );
};

export default Chargebacks;
