import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import parse from 'html-react-parser';
import { Tooltip } from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';

import { Button } from '../lib/common';
import { colors } from '../lib/styles';
import NoteEditor from './note-editor';

const useStyles = makeStyles({
  container: {
    overflowX: 'scroll',
  },
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
  table: {
    width: '100%',
  },
  hidden: {
    display: 'none',
  },
  submitBtn: {
    margin: 5,
  },
  noteContainer: {
    border: '1px solid grey',
    padding: '3px',
  },
  noteText: {
    width: '100%',
    minHeight: '20px',
  },
  notePre: {
    whiteSpace: 'pre-wrap',
  },
  noteBy: {
    backgroundColor: '#DDD',
  },
  navBtn: {
    minWidth: '36px',
    padding: '3px',
    margin: '5px',
    backgroundColor: colors.primaryColor,
    color: colors.white,
    borderRadius: '5px',
  },
  disabled: {
    minWidth: '36px',
    padding: '3px',
    margin: '5px',
    backgroundColor: '#AAA',
    color: '#DDD',
  },
});

const NotesList = (props) => {
  const { household, currentNotes, updateAll } = props;
  const { newNote } = household;
  const [noteText, setNoteText] = useState('');

  const classes = useStyles();

  const handleTextChange = (e) => {
    newNote.text = e;
    setNoteText(e);
  };

  const saveDisabled = !noteText || ('' + noteText).trim() === '';

  return (
    <div className={classes.container}>
      <table className={classes.table}>
        <tbody>
          {newNote ? (
            <tr>
              <td>
                <div style={{ marginBottom: '5px' }}>
                  <Tooltip title="Save" className={classes.navBtn}>
                    <Button
                      className={saveDisabled ? classes.disabled : classes.navBtn}
                      onClick={props.handleSaveHousehold}
                      disabled={!noteText}
                    >
                      <SaveIcon className={saveDisabled ? classes.disabled : classes.navBtn}/>
                      <span style={{ fontSize: '12px', marginRight: '5px' }}>Save</span>
                    </Button>
                  </Tooltip>
                </div>
                <div style={{ minHeight: '300px' }}>
                  <NoteEditor value={noteText} onChange={handleTextChange} />
                </div>
              </td>
            </tr>
          ) : (
            <tr>
              <td>
                <Button
                  householdadd
                  className={classes.submitBtn}
                  onClick={() => {
                    const note = {
                      text: '',
                    };
                    household.newNote = note;
                    setNoteText('');
                    updateAll();
                  }}
                >
                  Add Note
                </Button>
                <Button
                  variant={null}
                  onClick={() => {
                    const height = 800;
                    const width = 600;
                    const left = (window.screen.width - width) / 2;
                    const top = (window.screen.height - height) / 2;
                    let options = `popup=true,height=${height},width=${width},top=${top},left=${left}`;
                    options += 'location=no,toolbar=no,menubar=no';
                    const url = `/household/${household.id}/notes-archive`;
                    window.open(url, '_blank', options);
                  }}
                  style={{
                    marginLeft: '10px',
                    color: colors.primaryColor,
                    textTransform: 'capitalize',
                  }}
                >
                  View Archived Notes
                </Button>
              </td>
            </tr>
          )}
          {currentNotes.map((n, idx) => {
            return (
              <tr key={'note' + idx}>
                <td>
                  <div className={classes.noteContainer}>
                    <div className={classes.noteText}>
                      {parse(n.text)}
                    </div>
                    <div className={classes.noteBy}>
                      <span style={{ marginRight: '10px' }}>
                        {n.created_at ? moment(n.created_at).format('MM/DD/yyyy HH:mm') : ''}
                      </span>
                      <span>By: {n.firstname} {n.lastname} #{n.num}</span>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default NotesList;
