import React from 'react';
import moment from 'moment';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import DeleteButton from './delete-button';
import states from '../lib/states';
import {
  TextField, Select, GridForm, MaskInput,
} from '../lib/common';

const ClientEmploymentForm = (props) => {
  const {
    update,
    employment,
    idx,
    employmentStatusTypes,
    employmentOccupationTypes,
    scpErrors,
  } = props;

  const handleTextChange = (e) => {
    const { name, value } = e.target || e;
    update({ ...employment, [name]: value });
  };

  const handleDelete = () => {
    employment.to_delete = true;
    update(employment);
  };

  const {
    empaddress,
    empcity,
    empstate,
    empzip,
    employment_status_id,
    occupation_type_id,
    employer_name,
    employer_phone,
    job_title,
    year_hired,
    other_occupation,
  } = employment;

  let otherOccupationId = 0;
  employmentOccupationTypes.forEach((type) => {
    if (type.name.toLowerCase() === 'other') {
      otherOccupationId = type.id;
    }
  });

  const currYear = moment().year();
  const startYear = currYear - 120;

  function getEmployedStatusName(id) {
    let statusName = '';
    employmentStatusTypes.forEach((es) => {
      if (es.id === id) {
        statusName = es.name;
      }
    });
    return statusName;
  }

  return (
    <GridForm>
      <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
        Employment {'#' + (idx + 1)}
        - {getEmployedStatusName(employment_status_id)}
        - {employer_name || ''}
        <DeleteButton handleDelete={handleDelete}/>
      </Typography>
      {
        employment_status_id
        && employmentStatusTypes.find((type) => type.id === employment_status_id)?.name.includes('Yes')
          ? (
            <>
              <Grid container spacing={2}>
                <Select
                  label="Employment Status"
                  value={employment_status_id}
                  name="employment_status_id"
                  onChange={handleTextChange}
                  error={scpErrors?.employment_status_id}
                  errorMsg={scpErrors?.employment_status_id}

                >
                  {employmentStatusTypes.sort((a, b) => a.order_val - b.order_val).map((i) => {
                    return (<MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>);
                  })}
                </Select>
                <TextField
                  id="employer_name"
                  name="employer_name"
                  label="Employer Name"
                  value={employer_name}
                  onChange={handleTextChange}
                  error={scpErrors?.employer_name}
                  errorMsg={scpErrors?.employer_name}
                  expand
                />
              </Grid>
              <Box
                display="grid"
                gridTemplateColumns="repeat(15, 1fr)"
                gap={2}
                width={985}
              >
                <Box gridColumn="span 3">
                  <TextField
                    label="Job Title"
                    name="job_title"
                    value={job_title}
                    onChange={handleTextChange}
                    expand
                    error={scpErrors?.job_title}
                    errorMsg={scpErrors?.job_title}
                  />
                </Box>
                <Box gridColumn="span 3">
                  <MaskInput
                    label="Employer Phone Number"
                    name="employer_phone"
                    value={employer_phone}
                    mask_type="tel"
                    onChange={handleTextChange}
                    error={scpErrors?.employer_phone}
                    errorMsg={scpErrors?.employer_phone}
                  />
                </Box>
                <Box gridColumn="span 2">
                  <TextField
                    label="Year Started"
                    value={year_hired}
                    name="year_hired"
                    type="year"
                    onChange={handleTextChange}
                    min={startYear}
                    max={currYear}
                    error={scpErrors?.year_hired}
                    errorMsg={scpErrors?.year_hired}
                  />
                </Box>
                <Box gridColumn="span 3">
                  <Select
                    label="Occupation Type"
                    value={occupation_type_id}
                    name="occupation_type_id"
                    onChange={handleTextChange}
                  >
                    {employmentOccupationTypes.map((i) => {
                      return (<MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>);
                    })}
                  </Select>
                </Box>
                {occupation_type_id === otherOccupationId && (
                  <Box gridColumn="span 3">
                    <TextField
                      label="Other Occupation"
                      value={other_occupation}
                      name="other_occupation"
                      onChange={handleTextChange}
                    />
                  </Box>
                )}
              </Box>
              <Box
                display="grid"
                gridTemplateColumns="repeat(15, 1fr)"
                gap={2}
                width={985}
              >
                <Box gridColumn="span 5">
                  <TextField
                    label="Employer Address"
                    value={empaddress}
                    name="empaddress"
                    onChange={handleTextChange}
                    error={scpErrors?.empaddress}
                    errorMsg={scpErrors?.empaddress}
                  />
                </Box>
                <Box gridColumn="span 3">
                  <TextField
                    label="City"
                    value={empcity}
                    name="empcity"
                    onChange={handleTextChange}
                    error={scpErrors?.empcity}
                    errorMsg={scpErrors?.empcity}
                  />
                </Box>
                <Box gridColumn="span 3">
                  <Select
                    label="State"
                    value={empstate}
                    name="empstate"
                    onChange={handleTextChange}
                    error={scpErrors?.empstate}
                    errorMsg={scpErrors?.empstate}
                  >
                    {states.map((state) => <MenuItem value={state[0]} key={state[0]}>{state[1]}</MenuItem>)}
                  </Select>
                </Box>
                <Box gridColumn="span 3">
                  <MaskInput
                    mask_type="zip"
                    label="Postal Code"
                    value={empzip}
                    name="empzip"
                    onChange={handleTextChange}
                    error={scpErrors?.empzip}
                    errorMsg={scpErrors?.empzip}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <Grid container spacing={2}>
              <Select
                label="Employment Status"
                value={employment_status_id}
                name="employment_status_id"
                onChange={handleTextChange}
                error={scpErrors?.employment_status_id}
                errorMsg={scpErrors?.employment_status_id}
              >
                {employmentStatusTypes.sort((a, b) => a.order_val - b.order_val).map((i) => {
                  return (<MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>);
                })}
              </Select>
            </Grid>
          )
        }
    </GridForm>
  );
};

export default ClientEmploymentForm;
