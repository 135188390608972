import React, { useState } from 'react';
import { clone } from 'lodash';
import moment from 'moment';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import states from '../lib/states';
import {
  TextField, Select, Button, GridForm, MaskInput,
} from '../lib/common';
import useUser from '../lib/useUser';
import { apiFetch } from '../lib/fetch';

const useStyles = makeStyles({
  formControl: {
    margin: '10px',
    display: 'flex',
    width: '80%',
    '& div': {
      marginRight: '15px',
      minWidth: '175px',
    },
  },
  errMsg: {
    color: 'red',
    fontSize: '1.2em',
    fontWeight: 'bold',
    margin: '10px',
  },
  msg: {
    color: 'green',
    fontSize: '1.2em',
    fontWeight: 'bold',
    margin: '10px',
  },
  content: {
    margin: '10px',
    padding: '10px',
  },
});

const AgentForm = () => {
  const user = useUser();
  const [agent, setAgent] = useState(clone(user));
  const [error, setError] = useState(null);
  const [msg, setMsg] = useState(null);
  const classes = useStyles();

  const handleTextChange = (e) => {
    const { name, value } = e.target || e;
    const updatedAgent = { ...agent, [name]: value };
    setAgent(updatedAgent);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    const updatedAgent = { ...agent, [name]: moment(value).toISOString() };
    setAgent(updatedAgent);
  };

  const {
    firstname,
    lastname,
    phone,
    address,
    city,
    state,
    postalcode,
    ssn,
    dob,
    ein,
    account_num,
    routing_num,
    business_name,
  } = agent;

  const submitInfo = async () => {
    setError(null);
    if ((business_name && !ein) || (ein && !business_name)) {
      setError(new Error('EIN and Business Name must be provided together.'));
      return null;
    }

    const body = {
      firstname,
      lastname,
      phone,
      address,
      city,
      state,
      postalcode,
      ssn,
      dob,
      ein,
      account_num,
      routing_num,
      business_name,
    };
    try {
      await apiFetch.put(`/users/${user.id}/settings`, body);
      setMsg('Successfully updated your information.');
      document.location.reload();
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <GridForm>
        <h1>Update your personal information.</h1>
        <div className={classes.errMsg}>{error ? error.message : ''}</div>
        <div className={classes.msg}>{msg}</div>
        <Grid container spacing={2}>
          <TextField
            name="firstname"
            label="First Name"
            value={firstname}
            required={true}
            onChange={handleTextChange}
          />
          <TextField
            label="Last Name"
            name="lastname"
            value={lastname}
            required
            onChange={handleTextChange}
          />
        </Grid>
        <Grid container spacing={2}>
          <MaskInput
            label="Phone Number"
            name="phone"
            value={phone}
            required={true}
            onChange={handleTextChange}
            mask_type="tel"
          />
          <MaskInput
            label="Social Security Number"
            value={ssn}
            name="ssn"
            required
            onChange={handleTextChange}
            mask_type="ssn"
          />
          <TextField
            label="Date of Birth"
            value={dob}
            name="dob"
            onChange={handleDateChange}
            type="date"
            required={true}
          />
        </Grid>
        <Box
          display="grid"
          gridTemplateColumns="repeat(15, 1fr)"
          gap={2}
          width={985}
        >
          <Box gridColumn="span 9">
            <TextField
              label="Physical Address"
              value={address}
              name="address"
              required
              onChange={handleTextChange}
              expand
            />
          </Box>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="repeat(15, 1fr)"
          gap={2}
          width={985}
        >
          <Box gridColumn="span 3">
            <TextField
              label="City"
              value={city}
              name="city"
              required
              onChange={handleTextChange}
            />
          </Box>
          <Box gridColumn="span 3">
            <Select
              label="State"
              value={state}
              required={true}
              name="state"
              onChange={handleTextChange}
            >
              {states.map((state) => <MenuItem value={state[0]} key={state[0]}>{state[1]}</MenuItem>)}
            </Select>
          </Box>
          <Box gridColumn="span 3">
            <MaskInput
              mask_type="zip"
              label="Postal Code"
              value={postalcode}
              name="postalcode"
              required
              onChange={handleTextChange}
            />
          </Box>
        </Box>
        <Grid container spacing={2}>
          <TextField
            label="Business Name"
            name="business_name"
            value={business_name}
            onChange={handleTextChange}
          />
          <MaskInput
            label="EIN"
            value={ein}
            name="ein"
            mask_type="ein"
            onChange={handleTextChange}
          />
        </Grid>
        <Typography variant="h6">Banking Information</Typography>
        <Grid container spacing={2}>
          <TextField
            label="Bank Account Number"
            value={account_num}
            name="account_num"
            onChange={handleTextChange}
          />
          <MaskInput
            label="Routing Number"
            value={routing_num}
            name="routing_num"
            onChange={handleTextChange}
            pattern="#########"
          />
        </Grid>
        <Button
          onClick={submitInfo}
          disabled={!address || !phone || !city || !state || !firstname || !ssn || !dob}
          sx={{ margin: 1 }}
        >
          Save
        </Button>
      </GridForm>
    </div>
  );
};

export default AgentForm;
